<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Stock Out
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-form @submit.prevent="submitStockOutForm">
      <validation-observer
        ref="inventoryItemCreateForm"
        #default="{invalid}"
      >
        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="UploadIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Stock-Out List
            </h3>
          </template>
          <b-row
            v-if="!isMobile()"
            style="margin-bottom: 6px"
          >
            <b-col
              lg="7"
              md="7"
              sm="4"
            >Item(s)
            </b-col>
            <b-col
              lg="2"
              md="2"
              sm="4"
            >Store</b-col>
            <b-col
              lg="2"
              md="2"
              sm="4"
            >Quantity</b-col>
          </b-row>
          <div v-if="stockItems.length > 0">
            <b-row
              v-for="(stockItem, index) in stockItems"
              :id="'stockItems' + index"
              :key="index"
            >
              <b-col
                lg="7"
                md="7"
                sm="12"
              >
                <span v-if="isMobile()">Item(s)</span>
                <div
                  class="d-flex justify-content-between"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Name"
                    vid="name"
                    rules="required"
                    style="width: 100%"
                  >
                    <b-form-group
                      :label-for="'itemName'+ index"
                      :class="{ 'is-invalid': errors.length }"
                    >
                      <b-form-input
                        :id="'itemName'+ index"
                        v-model="stockItem.name"
                        name="name"
                        :state="(errors.length > 0) ? false : null"
                        placeholder="Item name"
                        disabled
                        class="disabled-clear"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <b-img
                    class="item-img-block"
                    :src="stockItem.image ? stockItem.image : defaultImage"
                  />
                </div>
              </b-col>
              <b-col
                lg="4"
                md="4"
                sm="12"
              >
                <b-row
                  v-for="(storeData, storeIndex) in stockItem.stores"
                  :key="storeIndex"
                >
                  <b-col
                    lg="6"
                    md="6"
                    sm="12"
                  >
                    <span v-if="isMobile()">Store</span>
                    <validation-provider
                      #default="{ errors }"
                      name="Store"
                      :vid="`store_${index}_${storeIndex}`"
                      rules="required"
                    >
                      <b-form-group
                        :label-for="`store_'+index'_${storeIndex}`"
                        class="mb-0"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <v-select
                          :id="`store_${index}_${storeIndex}`"
                          v-model="storeData.store"
                          label="name"
                          placeholder="Select a store"
                          :options="storeDataOptionComputation(stockItem.item)"
                          :reduce="name => name._id"
                          :clearable="false"
                          @input="stockStoreValidation(index, storeIndex, stockItem.item, storeData.store)"
                        >
                          <template #option="{ name, department }">
                            <span style="font-size: 14px;font-weight: 400;"> {{ name }}</span>
                            <br>
                            <span
                              style="font-weight: 500;font-size: 12px;"
                              class="text-muted"
                            >
                              {{ department.name }}
                            </span>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    lg="5"
                    md="5"
                    sm="12"
                  >
                    <span
                      v-if="isMobile()"
                      class="mt-50 d-block"
                    >
                      Quantity
                    </span>
                    <div class="store-options">
                      <validation-provider
                        #default="{ errors }"
                        name="Quantity"
                        :vid="`quantity_${index}_${storeIndex}`"
                        rules="required|min_value:1|numeric"
                        style="width: 100%;"
                      >
                        <b-form-group
                          :label-for="`quantity_${index}_${storeIndex}`"
                          :class="{ 'is-invalid': errors.length }"
                        >
                          <b-form-input
                            :id="`quantity_${index}_${storeIndex}`"
                            v-model="storeData.quantity"
                            name="quantity"
                            placeholder="0"
                            @input="stockQuantityValidation(index, storeIndex, stockItem.item, storeData.store, storeData.quantity)"
                            @keypress="validateNumberInput"
                            @paste="validateNumberPaste"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          <small
                            v-if="storeData.quantityError"
                            class="text-danger"
                          >
                            {{ storeData.quantityErrorMessage }}
                          </small>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col
                    lg="1"
                    md="1"
                    sm="12"
                    :class="{'p-0':!isMobile()}"
                  >
                    <div
                      v-if="stockItem.stores.length > 1"
                    >
                      <b-button
                        variant="flat-primary"
                        class="pl-0 primary-button-with-background no-background"
                        type="button"
                        @click="removeStoreData(index,storeIndex)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25 trash-icon"
                        />
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="none"
                  class="text-primary add-more-store-btn"
                  :class="{'mb-0':isMobile(), 'mb-2':!isMobile()}"
                  @click="addMoreStore(index)"
                >
                  Add more store
                </b-button>
              </b-col>
              <b-col
                md="1"
                lg="1"
                sm="12"
                :class="{'p-0':!isMobile()}"
              >
                <b-button
                  variant="flat-primary"
                  class="pl-0 primary-button-with-background no-background"
                  type="button"
                  @click="removeStockItem(index)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-25 trash-icon"
                  />
                </b-button>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col
              lg="7"
              md="7"
              sm="12"
            >
              <span v-if="isMobile()">Item(s)</span>
              <div
                class="d-flex justify-content-between search-bar"
              >
                <b-form-input
                  id="search_name"
                  v-model="searchInput"
                  label="Item(s)"
                  name="name"
                  placeholder="Search item"
                  autocomplete="off"
                  @keyup="submitSearchInput()"
                />
                <b-img
                  class="item-img-block"
                  :src="defaultImage"
                />
              </div>
              <div v-show="searchInputResult">
                <div
                  class="search-results mt-50"
                >
                  <div>
                    <b-list-group
                      v-if="storeItemSearchInputData.length"
                      flush
                    >
                      <span class="found-text-block">Inventory items found in this store</span>
                      <b-list-group-item
                        v-for="(storeItem, sIndex) in storeItemSearchInputData"
                        :key="sIndex"
                        class="cursor-pointer"
                        @click="addSearchInputStoreItem(sIndex, storeItem.item._id)"
                      >
                        <div class="d-flex">
                          <b-img
                            class="item-img-block"
                            :src="storeItem.item.image ? storeItem.item.image : defaultImage"
                          />
                          <div
                            class="search-item-text-block"
                          >
                            <h6>{{ storeItem.item.name }}</h6>
                            <small class="text-muted">{{ storeItem.item.sku }}</small>
                          </div>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </div>
              </div>
              <b-card
                v-show="searchResultNotFound"
                no-body
                class="mt-50"
              >
                <div class="result-not-found-block">
                  <h5>No inventory item found.</h5>
                </div>
              </b-card>
            </b-col>
            <b-col
              lg="4"
              md="4"
              sm="12"
            >
              <b-row>
                <b-col
                  lg="6"
                  md="6"
                  sm="12"
                >
                  <span
                    v-if="isMobile()"
                    class="mt-50 d-block"
                  >
                    Store
                  </span>
                  <v-select
                    id="search_store"
                    v-model="store"
                    label="name"
                    placeholder="Select a store"
                    :options="permittedDataOptions"
                    :reduce="name => name._id"
                    :clearable="false"
                    @input="submitSearchInput()"
                  >
                    <template #option="{ name, department }">
                      <span style="font-size: 14px;font-weight: 400;"> {{ name }}</span>
                      <br>
                      <span
                        style="font-weight: 500;font-size: 12px;"
                        class="text-muted"
                      >
                        {{ department.name }}
                      </span>
                    </template>
                  </v-select>
                </b-col>
                <b-col
                  lg="5"
                  md="5"
                  sm="12"
                >
                  <span
                    v-if="isMobile()"
                    class="mt-50 d-block"
                  >
                    Quantity
                  </span>
                  <b-form-input
                    id="search_quantity"
                    v-model="quantity"
                    name="quantity"
                    placeholder="0"
                    @keypress="validateNumberInput"
                    @paste="validateNumberPaste"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr>
          <div class="d-flex justify-content-between">
            <p><span class="text-bold-black mr-75">Inventory Item(s)</span>  {{ stockItems.length ? stockItems.length : '-' }}</p>
            <p><span class="text-bold-black">Total Quantity</span> <span class="text-danger ml-75">-{{ stockOutTotalQuantity }}</span></p>
          </div>
        </b-card>
        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Stock Out Remarks
            </h3>
          </template>
          <b-row>
            <b-col md="12">
              <b-form-group
                label=""
                label-for="remarks"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Minimum Stock"
                  vid="minimum-stock"
                  rules=""
                >
                  <b-form-textarea
                    id="remarks"
                    v-model="remarks"
                    :state="(errors.length > 0) ? false : null"
                    name="remarks"
                    rows="5"
                    placeholder="Any other information for the stock out"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="remarksValidation"
                    class="text-danger"
                  >
                    {{ remarksError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-nav class="wrap-border save-nav">
          <li><span class="ml-2">Stock Out by <b>{{ user.name }}</b> on {{ dateFormatWithTime(new Date()) }}</span></li>
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'inventory-stock-list', query: { store: storeOriginal } }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-stock-out-items
              type="button"
              variant="success"
              :disabled="invalid || stockItems.length < 1 || catchQuantityError"
              @click="stockOutAllItemsPreview()"
            >
              <feather-icon
                icon="UploadIcon"
                class="mr-50"
              />
              <span class="align-middle">Confirm</span>
            </b-button>
          </li>
        </b-nav>
      </validation-observer>
    </b-form>
    <!-- stock out items modal -->
    <b-modal
      id="modal-stock-out-items"
      ref="stock-out-items-modals"
      modal-class="status-update-modal"
      size="lg"
      centered
      no-stacking
      no-close-on-backdrop
      @ok="submitStockOutForm"
      @cancel="closeStockOutItemsModal"
    >
      <template #modal-header>
        <div class="stock-items-modal-header">
          <feather-icon
            icon="UploadIcon"
            style="color: #EE1C24; margin-bottom: 7px;"
            size="60"
          />
          <div class="stock-in-item-modal-heading-block">
            <h4 style="font-weight: 500; font-size: 21px; color: #000000;">
              Proceed to Stock-Out?
            </h4>
            <p style="color: #333333;">
              Please review the inventory item(s) below to be stocked-out.
            </p>
          </div>
        </div>
      </template>
      <b-table-simple
        responsive
        sticky-header
        class="shadow-table"
      >
        <b-thead>
          <b-tr>
            <b-th style="height: 0px !important;">
              Item
            </b-th>
            <b-th style="height: 0px !important;">
              Store
            </b-th>
            <b-th style="height: 0px !important;">
              Quantity
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(stockInItem, stockIndex) in stockOutPreviewItems"
            :key="stockIndex"
          >
            <b-td>
              <div class="d-flex">
                <b-img
                  class="mb-1 mb-sm-0 user__avatar img-preview-block"
                  :src="stockInItem.image ? stockInItem.image : defaultImage"
                />
                <div
                  class="ml-1"
                >
                  <span class="text-bold-black">{{ stockInItem.name || '-' }}</span>
                  <br>
                  <span class="text-muted">{{ stockInItem.sku || '-' }}</span>
                </div>
              </div></b-td>
            <b-td>
              {{ resolveStoreName(stockInItem.store) }}
            </b-td>
            <b-td>
              <span
                class="text-danger"
              >-{{ stockInItem.quantity }}</span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div class="d-flex justify-content-between">
        <p><span class="text-bold-black mr-75">Inventory Item(s)</span>  {{ stockItems.length }}</p>
        <p><span class="text-bold-black">Total Quantity</span> <span class="text-danger ml-75">-{{ stockOutTotalQuantity }}</span></p>
      </div>
      <hr class="mt-0">
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          No, go back
        </b-button>
        <b-button
          variant="primary"
          type="button"
          @click="ok()"
        >
          <span class="align-middle">Yes, proceed</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ stock out items modal -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BForm, BButton, BNav, BImg, BListGroup, BListGroupItem, BTableSimple, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store/index'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ToastificationContentCustom from './ToastificationContentCustom.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BNav,
    BImg,
    BListGroup,
    BListGroupItem,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentCustom,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      saveStockOut: false,
      searchInput: '',
      storeOriginal: localStorage.getItem('storeVal'),
      store: localStorage.getItem('storeVal'),
      user: store.state.auth.userData,
      quantity: '',
      remarks: '',
      remarksError: 'Valid Remarks is required',
      remarksValidation: false,
      storeDataOptions: [],
      permittedDataOptions: [],
      stockItems: [],
      storeItemData: [],
      selectStockItem: JSON.parse(localStorage.getItem('items')) || [],
      storeItemSearchInputData: [],
      searchInputResult: false,
      searchResultNotFound: false,
      hasError: false,
      stockOutPreviewItems: [],
      openInventoryRequestTns: [],
      // validation rules
      required,
    }
  },
  computed: {
    stockOutTotalQuantity() {
      const allStockItems = this.stockItems.length
      if (allStockItems) {
        let initStockQuantity = 0
        this.stockItems.forEach(item => {
          if (item.stores.length) {
            item.stores.forEach(storeData => {
              initStockQuantity -= -storeData.quantity
            })
          }
        })
        return initStockQuantity
      }
      return 0
    },
    catchQuantityError() {
      let errorFound = 0
      this.stockItems.forEach(item => {
        item.stores.forEach(val => {
          const storeFound = this.storeItemData.find(sData => sData.item._id === item.item && sData.store === val.store)
          if (storeFound) {
            let initStockQuantity = 0
            this.openInventoryRequestTns.filter(obj => obj.item === item.item && obj.store === val.store).forEach(i => {
              initStockQuantity -= -i.totalQuantity
            })
            initStockQuantity -= -val.quantity
            if (initStockQuantity > storeFound.stock) {
              errorFound += 1
            }
          } else {
            errorFound += 1
          }
        })
      })
      if (errorFound > 0) {
        return true
      }
      return false
    },
  },
  beforeMount() {
    this.$http.get('inventory/category/respond-with/name-and-id')
      .then(response => {
        this.categories = response.data.categories ?? []

        this.category = this.categories[0]._id ?? ''
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  created() {
    this.$http.get('inventory/stock/respond-with/store-and-item')
      .then(response => {
        this.storeDataOptions = response.data.stores
        this.storeItemData = response.data.storeItems
        this.openInventoryRequestTns = response.data.openInventoryRequestTns
        if (this.selectStockItem.length) {
          this.selectStockItem.forEach(selectedItem => {
            const item = this.storeItemData.find(itemObj => itemObj.item._id === selectedItem.item && itemObj.store === selectedItem.store)
            if (item) {
              this.stockItems.push({
                item: item.item._id,
                name: item.item.name,
                image: item.item.image,
                sku: item.item.sku,
                stores: [{
                  store: this.store,
                  quantity: this.quantity,
                  quantityError: false,
                  quantityErrorMessage: '',
                }],
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${selectedItem.itemName} was deleted.` ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
        }

        this.permittedDataOptions = []
        this.storeDataOptions.forEach(iStore => {
          const filteredArray = iStore.roles.filter(o1 => this.user.roles.some(o2 => o1._id === o2._id))
          const foundPermission = filteredArray.filter(o => o.permissions.find(i => i.action === 'stock-out'))
          if (foundPermission.length) {
            this.permittedDataOptions.push(iStore)
          }
        })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  beforeRouteLeave(to, from, next) {
    if (!this.saveStockOut) {
      this.$swal({
        title: 'Unsaved Changes - Leave?',
        html: 'You have unsaved changes. Are you sure you want to leave this page?',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Yes, I'm Sure",
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-outline-primary no-focus-bg ml-1',
          cancelButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          localStorage.removeItem('storeVal')
          localStorage.removeItem('items')
          if (result.value) {
            next()
          } else {
            next(false)
          }
        })
    } else {
      next()
    }
  },
  methods: {
    storeDataOptionComputation(stockItemId) {
      const a = this.permittedDataOptions.filter(obj => {
        const found = this.storeItemData.find(sData => sData.item._id === stockItemId && sData.store === obj._id)
        if (found) {
          return true
        }
        return false
      })

      return a
    },
    addMoreStore(index) {
      this.stockItems[index].stores.push({
        store: '',
        quantity: '',
      })
    },
    removeStoreData(index, storeIndex) {
      this.stockItems[index].stores.splice(storeIndex, 1)
    },
    removeStockItem(stockIndex) {
      this.stockItems.splice(stockIndex, 1)
    },
    submitSearchInput() {
      this.storeItemSearchInputData = this.storeItemData.filter(item => item.store === this.store && item.item.deletedAt === null && (item.itemName.toLowerCase().includes(this.searchInput.toLowerCase()) || item.sku.toLowerCase().includes(this.searchInput.toLowerCase())))
      if (this.searchInput !== '') {
        this.searchInputResult = true
      } else {
        this.searchInputResult = false
      }
      if (!this.storeItemSearchInputData.length) {
        // this.searchResultNotFound = true
        if (this.searchInput === '') {
          this.searchResultNotFound = false
        } else {
          this.searchResultNotFound = true
        }
      } else {
        this.searchResultNotFound = false
      }
    },
    validateNumberInput(event) {
      if (event.key && !/^-?\d*[.]?\d*$/.test(event.key)) {
        event.preventDefault()
      }
    },
    validateNumberPaste(event) {
      const text = (event.originalEvent || event).clipboardData.getData('text/plain')
      if (text && !/^-?\d*[.]?\d*$/.test(text)) {
        event.preventDefault()
      }
    },
    addSearchInputStoreItem(index, itemId) {
      if (this.storeItemData.length) {
        const item = this.storeItemData.find(storeItemObj => storeItemObj.item._id === itemId && storeItemObj.store === this.store)
        if (item) {
          if (this.stockItems.length) {
            const foundItemId = this.stockItems.find(obj => obj.item === item.item._id)
            if (foundItemId) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'This item is already added!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              this.stockItems.push({

                item: item.item._id,
                name: item.item.name,
                image: item.item.image,
                sku: item.item.sku,
                stores: [{
                  store: this.store,
                  quantity: this.quantity,
                  quantityError: false,
                  quantityErrorMessage: '',
                }],
              })
              this.quantity = ''
              this.searchInput = ''
              this.searchInputResult = false
              this.storeItemSearchInputData.splice(index, 1)
            }
          } else {
            this.stockItems.push({
              item: item.item._id,
              name: item.item.name,
              image: item.item.image,
              sku: item.item.sku,
              stores: [{
                store: this.store,
                quantity: this.quantity,
                quantityError: false,
                quantityErrorMessage: '',
              }],
            })
            this.quantity = ''
            this.searchInput = ''
            this.searchInputResult = false
            this.storeItemSearchInputData.splice(index, 1)
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'This item is not found on this store.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    stockStoreValidation(stockItemIndex, storeIndex, stockItemId, storeId) {
      const storeFound = this.storeItemData.find(sData => sData.item._id === stockItemId && sData.store === storeId)
      const selectedStoreFound = this.stockItems[stockItemIndex].stores.filter(obj => obj.store === storeId)
      if (selectedStoreFound.length > 1) {
        this.stockItems[stockItemIndex].stores[storeIndex].store = ''
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'This store is already selected in this selected item, please select another store.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      } else {
        this.stockItems[stockItemIndex].stores[storeIndex].store = storeId
      }
      if (!storeFound) {
        this.stockItems[stockItemIndex].stores[storeIndex].store = ''
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Selected inventory item was not found for the selected store!',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      }
    },
    stockQuantityValidation(stockItemIndex, storeIndex, stockItemId, storeId, quantity) {
      const storeFound = this.storeItemData.find(sData => sData.item._id === stockItemId && sData.store === storeId)
      if (storeFound) {
        let initStockQuantity = 0
        this.openInventoryRequestTns.filter(obj => obj.item === stockItemId && obj.store === storeId).forEach(item => {
          initStockQuantity -= -item.totalQuantity
        })
        const finalQuantity = initStockQuantity - (-quantity)
        if (finalQuantity > storeFound.stock) {
          // this.hasError = true
          // this.stockItems[stockItemIndex].stores[storeIndex].quantity = ''
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: `Stock not available more than ${storeFound.stock} in this item!`,
          //     icon: 'AlertTriangleIcon',
          //     variant: 'warning',
          //   },
          // })
          this.stockItems[stockItemIndex].stores[storeIndex].quantityError = true
          this.stockItems[stockItemIndex].stores[storeIndex].quantityErrorMessage = `Out of stock, ${(storeFound.stock - initStockQuantity)} available`
        } else {
          this.stockItems[stockItemIndex].stores[storeIndex].quantityError = false
          // this.hasError = false
        }
      }
    },
    closeStockOutItemsModal() {
      this.$root.$emit('bv::hide::modal', 'modal-stock-out-items', '')
      this.stockOutPreviewItems = []
    },
    resolveStoreName(storeId) {
      const storeData = this.storeDataOptions.find(s => s._id === storeId)
      if (storeData) {
        return storeData.name
      }
      return ''
    },
    stockOutAllItemsPreview() {
      this.stockOutPreviewItems = []
      this.stockItems.forEach(stockItem => {
        stockItem.stores.forEach(storeData => {
          this.stockOutPreviewItems.push({
            item: stockItem.item,
            name: stockItem.name,
            image: stockItem.image,
            sku: stockItem.sku,
            store: storeData.store,
            quantity: storeData.quantity,
          })
        })
      })
    },
    submitStockOutForm() {
      const formData = new FormData()
      const itemsArray = []
      const storesArray = []
      let storeList = ''
      this.stockItems.forEach(item => {
        itemsArray.push(item.item)
        item.stores.forEach(storeData => {
          const exists = storesArray.find(obj => obj.store === storeData.store)
          if (exists) {
            const itemArray = {
              item: item.item,
              quantity: +storeData.quantity,
            }
            exists.totalItems += 1
            exists.totalQuantity -= -storeData.quantity
            exists.items.push(itemArray)
          } else {
            const itemArray = {
              item: item.item,
              quantity: +storeData.quantity,
            }
            const storeArr = {
              store: storeData.store,
              items: [itemArray],
              totalItems: 1,
              totalQuantity: +storeData.quantity,
            }

            storesArray.push(storeArr)
          }
          const found = this.storeDataOptions.find(obj => obj._id === storeData.store)
          if (found) {
            if (storeList === '') {
              storeList += `${found.name} (${found.department.name})`
            } else {
              storeList += `, ${found.name} (${found.department.name})`
            }
          }
        })
      })
      formData.append('items', JSON.stringify(itemsArray))
      formData.append('stores', JSON.stringify(storesArray))
      // formData.append('items', JSON.stringify(this.stockItems))
      // formData.append('totalItems', this.stockItems.length)
      // formData.append('totalQuantity', this.stockOutTotalQuantity)
      formData.append('remarks', this.remarks)
      this.$http.post('inventory/stock/stock-out', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(() => {
          localStorage.removeItem('storeVal')
          localStorage.setItem('storeOperation', 'stock-out')
          localStorage.setItem('items', JSON.stringify(this.stockItems))
          this.closeStockOutItemsModal()
          const toastMessage = `<span class="text-bold-black">${this.stockItems.length} inventory item(s)</span> has been stocked-out from these store(s): <span class="text-bold-black">${storeList}</span>.`
          // this.closeRegisterNewItemModal()
          this.$toast({
            component: ToastificationContentCustom,
            props: {
              title: 'Stock-Out Successful',
              text: toastMessage,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.saveStockOut = true
          this.$router.push({ name: 'inventory-stock-list', query: { store: this.storeOriginal } })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>
  .item-img-block{
    box-sizing: border-box;
    width: 38px;
    height: 38px;
    border: 1px solid #D2DCEA;
    border-radius: 5px;
    margin-left: 2px;
  }
  .trash-icon{
     cursor: pointer;
  }
  .search-bar{
     position: relative;
  }
  .search-results{
    width: 97.5%;
    position: absolute;
    top: 5;
    z-index: 1;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), rgba(0, 0, 0, 0.0001);
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    overflow-y: auto;
    max-height: 340px;
  }
  .result-not-found-block{
    width: 100%;
    position: absolute;
    top: 5;
    left: 0;
    z-index: 1;
    text-align: center;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), rgba(0, 0, 0, 0.0001);
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 20px 0;
  }
  .found-text-block{
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #333333;
      padding: 15px 30px 5px 22px;
  }
  .store-options{
    display: flex;
    justify-content: space-between;
  }
  .add-more-store-btn {
    margin-left: -14px;
    margin-top: -13px;
  }
  /* stock out items modal */
  .stock-items-modal-header{
    margin: 20px auto 0px auto;
    text-align: center;
  }
  .img-preview-block{
      box-sizing: border-box;
      width: 38px;
      height: 38px;
      border: 1px solid #D2DCEA;
      border-radius: 5px;
  }
  /* ./ stock out items modal */
</style>
